import {
  Button,
  Grid,
  List,
  ListItem,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { beyaz } from "../../config/colors";
const FooterFour = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const handleButtonClick = () => {
    const emailAddress = "hello@gaulaxy.com";
    const mailtoLink = `mailto:${emailAddress}`;

    window.location.href = mailtoLink;
  };
  return (
    <Grid
      container
      direction="column"
      alignItems={matches ? "flex-start" : "center"}
      spacing={1}
      sx={{ p: 0, width: "100%" }}
    >
      <Grid item>
        <Typography
          sx={{
            fontFamily: "Manrope, sans-serif",
            fontWeight: "bold",
            fontSize: "16px",
            color: beyaz,
          }}
        >
          documents
        </Typography>
      </Grid>
      <Grid item sx={{ py: 0 }}>
        <List sx={{ py: 0 }}>
          <ListItem sx={{ px: 0 }}>
            <Button
              sx={{
                p: 0,
                justifyContent: !matches && "center",
                textTransform: "none",
              }}
              onClick={() =>
                window.open(
                  "https://whitepaper.gamerarena.com/gau-token/tokenomics",
                  "_blank"
                )
              }
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "medium",
                  fontSize: "14px",
                  color: beyaz,
                }}
              >
                tokenomics
              </Typography>
            </Button>
          </ListItem>
          <ListItem sx={{ px: 0 }}>
            <Button
              sx={{
                p: 0,
                justifyContent: !matches && "center",
                textTransform: "none",
              }}
              onClick={() => window.open("privacy-policy", "_blank")}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "medium",
                  fontSize: "14px",
                  color: beyaz,
                }}
              >
                privacy policy
              </Typography>
            </Button>
          </ListItem>
          {/* <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
              }}
            >
              Token Listing
            </Typography>
          </ListItem>
          <ListItem sx={{ px: 0, justifyContent: !matches && "center" }}>
            <Typography
              sx={{
                fontFamily: "Manrope, sans-serif",
                fontWeight: "medium",
                fontSize: "14px",
                color: beyaz,
              }}
            >
              NFT Listing
            </Typography>
          </ListItem> */}
          <ListItem sx={{ p: 0 }}>
            <Button
              sx={{
                px: 0,
                justifyContent: !matches && "center",
                textTransform: "none",
              }}
              onClick={handleButtonClick}
            >
              <Typography
                sx={{
                  fontFamily: "Manrope, sans-serif",
                  fontWeight: "medium",
                  fontSize: "14px",
                  color: beyaz,
                }}
              >
                contact us
              </Typography>
            </Button>
          </ListItem>
        </List>
      </Grid>
      {!matches && (
        <Grid item>
          <Typography
            sx={{
              fontFamily: "Manrope, sans-serif",
              fontWeight: "medium",
              fontSize: "14px",
              color: beyaz,
            }}
          >
            © 2024 Gamer Arena
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default FooterFour;
